import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../../static/images/logo/logo.svg"
import Seo from "../../../components/seo"
import BookHeader from "../../../components/bookPageHeader"
import BookSlider from "../../../components/bookSlider"
import ReactBook from "../../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../../static/images/commonImg/nodebook-advanced.svg"
//import NextBook2a from "../../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../../static/images/commonImg/proxyserver-book.png"
import TSmasterBook from "../../../../static/images/commonImg/typescript-master-book.svg"
import NextFullAppFolder1 from "../../../../static/images/commonImg/nextbook-app-fullstack-1.svg" 
import NextServerActionsBook from "../../../../static/images/commonImg/nextjs-server-actions.svg"
//import RegisterNewsLetterBody from "../../../components/registerNewsLetter"
import BookForm from "../../../components/bookForm"

//import AdNextFullstack from "./components/ad-next-fullstack-app-folder"

const generalData = {
    title: "Reactマスターブック　Zero to Hero",
    updated: "2024/2/21",
    urlToApp: "https://react-app-meal-finder.netlify.app",
    urlToCode1: "https://github.com/mod728/various-code/tree/master/chapter-4-last",
    urlToCode2: "https://github.com/mod728/various-code/tree/master/chapter-5-useContext",
    urlToCode3: "https://github.com/mod728/various-code/tree/master/chapter-5-last",
    urlToCode4: "https://github.com/mod728/various-code/tree/master/chapter-6-code",
    reviewLink1: "",
    reviewLink2: ""
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterBook, url: "https://monotein.base.shop/p/00002", description: "2日でTypeScript + Reactの苦手意識を捨てされる本。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用書。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。"},
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.js Server Actionsでつくるフルスタックアプリ", img: NextServerActionsBook, url: "https://www.amazon.co.jp/dp/B0DMQLP8Z9", description: "Next.jsビギナー向けの入門書。<br/><br/>Next.jsとServer Actionsを使って、ログイン機能をもった本格的なアプリケーション開発の方法を紹介。<br/><br/>2024年10月にリリースされたばかりの最新のNext.jsバージョン15使用。"},
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.jsでつくるフルスタックサイト　前編・後編", img: NextFullAppFolder1, url: "https://www.amazon.co.jp/dp/B0DRHF4KGM", description: "最新バージョンのNext.jsとAppフォルダを使って、バックエンドとフロントエンドの開発手法を紹介。<br/><br/>アプリ開発の基礎の基礎から、オンラインでの公開方法までの流れをすべて紹介している充実の内容です。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるReactアプリ with TypeScript", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: "「React開発のスタンダードReact + TypeScriptを学んでみたい」<br/><br/>という人向けのReact + TypeScript入門書です。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "見本アプリ", url: "https://react-app-meal-finder.netlify.app"},
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "JSON Formatter", url: "https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa"},
            {name: "ウェブアプリの例", url: "https://nextbook-fullstack-app-folder.vercel.app"},
            {name: "バックエンドAPIの例（全アイテム）", url: "https://nextbook-fullstack-app-folder.vercel.app/api/item/readall"},
            {name: "個別アイテムページ", url: "https://nextbook-fullstack-app-folder.vercel.app/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "バックエンドAPIの例（個別アイテム）", url: "https://nextbook-fullstack-app-folder.vercel.app/api/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "Note.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"}     
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "見本アプリ", url: "https://react-app-meal-finder.netlify.app"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "見本アプリ（最終系）", url: "https://react-app-meal-finder.netlify.app"},
            {name: "見本アプリ（第4章終了時）", url: "https://react-app-meal-finder-initial.netlify.app"},
            {name: "TheMealDB", url: "https://www.themealdb.com/api.php"},
            {name: "Sushiのレシピデータ", url: "https://www.themealdb.com/api/json/v1/1/search.php?s=sushi"},
            {name: "画像", url: "https://github.com/mod728/various-code/tree/master/chapter-4-last/src/assets"},
            {name: "ファビコン画像", url: "https://github.com/mod728/various-code/blob/master/chapter-4-last/public/favicon.svg"},
            {name: "CSS", url: "https://github.com/mod728/various-code/blob/master/chapter-4-last/src/styles/App.css"},
            {name: "第4章完了時のソースコード", url: "https://github.com/mod728/various-code/tree/master/chapter-4-last"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "見本アプリ（最終系）", url: "https://react-app-meal-finder.netlify.app"},
            {name: "useContext導入後のソースコード", url: "https://github.com/mod728/various-code/tree/master/chapter-5-useContext"},
            {name: "Netlify Drop", url: "https://app.netlify.com/drop"},
            {name: "第5章完了時のソースコード", url: "https://github.com/mod728/various-code/tree/master/chapter-5-last"},
        ]
    },
    {
        chapterNum: 6,
        data: [
            {name: "第6章各項目のコード", url: "https://github.com/mod728/various-code/tree/master/chapter-6-code"},
            {name: "useStateの記法", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/1useState-types.js"},
            {name: "useLayoutEffect", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/2useLayoutEffect.js"},
            {name: "useReducer 1", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/3useReducer-1.js"},
            {name: "useReducer 2", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/3useReducer-2.js"},
            {name: "useRef 1", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/4useRef-1.js"},
            {name: "useRef 2", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/4useRef-2.js"},
            {name: "プログレスバーの作り方", url: "https://monotein.com/blog/making-a-progress-bar-for-react"},
            {name: "memo（App.jsxコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/5memo.js"},
            {name: "memo（Child.jsxコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/5memo.js"},
            {name: "useCallback", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/6useCallback.js"},
            {name: "useMemo", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/7useMemo.js"},
            {name: "useTransition 1", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/8useTransition-1.js"},
            {name: "useTransition 2", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/8useTransition-2.js"},
            {name: "SuspenseとLazy（App.jsコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/9suspense-lazy.js"},
            {name: "SuspenseとLazy（Child.jsxコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/9suspense-lazy.js"},
            {name: "Redux 1", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/10redux-1.js"},
            {name: "Redux 2", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/10redux-2.js"},
            {name: "テスト（App.jsxコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/11test.js"},
            {name: "テスト（App.test.jsxコード）", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/11test.js"},
            {name: "サーバーコンポーネント", url: "https://github.com/mod728/various-code/blob/master/chapter-6-code/12server-component.js"},
        ]
    },
]

const ReactMasterBookLinksClean = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコード（第4章完了時）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            <li>ソースコード（第5章useContext導入後）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            <li>ソースコード（第5章完了時）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode3}>{generalData.urlToCode3}</a></li>
            <li>ソースコード（第6章各項目）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode4}>{generalData.urlToCode4}</a></li>
{/*             <li><span className="amazon-review">Amazonでレビューする（前編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li> */}
        </ul>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
    </div>
)

export default ReactMasterBookLinksClean
